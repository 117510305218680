import React from "react";
import { withWidth, Box, Container, Typography, Grid, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import Sire from "./assets/Sire.jpg";
import Dam from "./assets/Dam.jpg";

function Puppies(props) {
    return (
        <Box className={props.classes.lightBox} style={{ marginTop: "80px" }}>
            <Container>
                <Typography variant="h2" align="center">
                    About
                </Typography>

                <Box className={props.classes.lightBox}>
                    <Container disableGutters>
                        <Grid container>
                            <Grid item sm={12} md={6}>
                                <img src={Sire} alt="About Maroshegyi Vizsla Kennels" style={{ width: "100%" }} />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <Typography variant="h2" style={{ paddingTop: "25px" }}>
                                    Sire Name
                                </Typography>
                                <Box style={{ backgroundColor: "#fff", padding: "25px" }}>
                                    <Typography variant="h3" align="left" style={{ padding: "0 0 25px 0" }}>
                                        Short description
                                    </Typography>
                                    <Typography variant="body1" align="left">
                                        Long description
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                <Box className={props.classes.lightBox}>
                    <Container disableGutters>
                        <Grid container>
                            <Grid item sm={12} md={6}>
                                <img src={Dam} alt="About Maroshegyi Vizsla Kennels" style={{ width: "100%" }} />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <Typography variant="h2" style={{ paddingTop: "25px" }}>
                                    Dam Name
                                </Typography>
                                <Box style={{ backgroundColor: "#fff", padding: "25px" }}>
                                    <Typography variant="h3" align="left" style={{ padding: "0 0 25px 0" }}>
                                        Short description
                                    </Typography>
                                    <Typography variant="body1" align="left">
                                        Long description
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Container>
        </Box>
    );
}

export default withWidth()(Puppies);
