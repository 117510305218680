import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Container, Typography, withWidth } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Carousel from "react-material-ui-carousel";
import RotateRight from "@material-ui/icons/RotateRight";

const useStyles = makeStyles((theme) => ({
    carousel: {
        backgroundColor: "rgba(0,0,0,100)",
        // boxShadow:
        //     "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
    item: {
        width: "100%",
        overflow: "hidden",
        boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
    innerItem0: {
        width: "100%",
        backgroundSize: "cover",
        backgroundPosition: "left center",
    },
    innerItem1: {
        width: "100%",
        backgroundSize: "cover",
        backgroundPosition: "left center",
    },
    innerItem2: {
        width: "100%",
        backgroundSize: "cover",
        backgroundPosition: "left bottom",
    },
    line1: {
        margin: "0",
        position: "absolute",
        top: "35%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color: "#F6F8F9",
        backgroundColor: "rgba(0,0,0,0.75)",
        padding: "5px 10px 5px 10px",
        animation: "$fadeIn ease 1.75s",
        textTransform: "uppercase",
        whitespace: "nowrap",
        width: "100%",
    },
    line2: {
        margin: "0",
        position: "absolute",
        top: "55%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color: "#F6F8F9",
        backgroundColor: "rgba(0,0,0,0.75)",
        padding: "5px 10px 5px 10px",
        animation: "$fadeIn ease 1.75s",
        textTransform: "uppercase",
        whitespace: "nowrap",
        width: "100%",
    },
    line3: {
        margin: "0",
        position: "absolute",
        top: "60%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color: "#F6F8F9",
        backgroundColor: "rgba(0,0,0,0.75)",
        padding: "5px 10px 5px 10px",
        animation: "$fadeIn ease 1.75s",
    },
    itemLoad: {
        margin: "0",
        position: "absolute",
        top: "55%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    rotate: {
        animation: "$rotation 2s infinite linear",
    },
    "@keyframes rotation": {
        "0%": {
            transform: "rotate(0deg)",
        },
        "100%": {
            transform: "rotate(359deg)",
        },
    },
    "@keyframes fadeIn": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 100,
        },
    },
}));

const Loader = (props) => {
    return (
        <div className={props.classes.innerItem1}>
            <Typography
                variant="h2"
                align="center"
                className={props.classes.itemLoad}
                style={{ fontSize: props.fontSize }}
            >
                <RotateRight className={props.classes.rotate} color="secondary" />
            </Typography>
        </div>
    );
};

const Slider = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    let fontSize;

    switch (props.width) {
        case "xs":
            fontSize = "1em";
            break;
        case "sm":
            fontSize = "1.5em";
            break;
        case "md":
            fontSize = "2em";
            break;
        case "lg":
            fontSize = "2em";
            break;
        case "xl":
            fontSize = "2.5em";
            break;
        default:
            fontSize = "15px";
            break;
    }

    const [is0Loaded, set0Loaded] = React.useState(false);
    const [is1Loaded, set1Loaded] = React.useState(false);
    const [is2Loaded, set2Loaded] = React.useState(false);
    const [is3Loaded, set3Loaded] = React.useState(false);

    const [slide0, setSlide0] = React.useState(
        <Loader classes={classes} fontSize={fontSize} color={theme.palette.secondary.main} />
    );
    const [slide1, setSlide1] = React.useState(
        <Loader classes={classes} fontSize={fontSize} color={theme.palette.secondary.main} />
    );
    const [slide2, setSlide2] = React.useState(
        <Loader classes={classes} fontSize={fontSize} color={theme.palette.secondary.main} />
    );
    const [slide3, setSlide3] = React.useState(
        <Loader classes={classes} fontSize={fontSize} color={theme.palette.secondary.main} />
    );

    var imgPreloads = [];

    Object.keys(props.items).forEach((key) => {
        if (!imgPreloads[key]) {
            let img = new Image();
            img.onload = () => {
                if (key.includes("0")) set0Loaded(true);
                else if (key.includes("1")) set1Loaded(true);
                else if (key.includes("2")) set2Loaded(true);
                else if (key.includes("3")) set3Loaded(true);
            };
            img.src = props.images[key].default;
            imgPreloads[key] = img;
        }
    });

    const getLoadedSlide = (key, index) => {
        return (
            <>
                <Container maxWidth="md">
                    {props.items[key].line1 && (
                        <Typography
                            variant="body1"
                            align="center"
                            className={classes.line1}
                            style={{ fontSize: fontSize, width: "100%" }}
                        >
                            {props.items[key].line1}
                        </Typography>
                    )}
                    {props.items[key].line2 && (
                        <Typography
                            variant="body1"
                            align="center"
                            className={classes.line2}
                            style={{ fontSize: fontSize }}
                        >
                            {props.items[key].line2}
                        </Typography>
                    )}
                    {props.items[key].line3 && (
                        <Typography
                            variant="body1"
                            align="center"
                            className={classes.line3}
                            style={{ fontSize: fontSize }}
                        >
                            {props.items[key].line3}
                        </Typography>
                    )}
                </Container>
                <div
                    className={classes["innerItem" + index]}
                    style={{
                        backgroundImage: `url(${props.images[key].default})`,
                        //paddingTop: props.width === "xs" ? "50%" : "33.33%",
                        paddingTop: "50%",
                    }}
                ></div>
            </>
        );
    };

    useEffect(() => {
        if (is0Loaded) setSlide0(getLoadedSlide("image0", 0));
    }, [is0Loaded]);
    useEffect(() => {
        if (is1Loaded) setSlide1(getLoadedSlide("image1", 1));
    }, [is1Loaded]);
    useEffect(() => {
        if (is2Loaded) setSlide2(getLoadedSlide("image2", 2));
    }, [is2Loaded]);
    useEffect(() => {
        if (is3Loaded) setSlide3(getLoadedSlide("image3", 3));
    }, [is3Loaded]);

    const getSlide = (key) => {
        if (key.includes("0")) return slide0;
        else if (key.includes("1")) return slide1;
        else if (key.includes("2")) return slide2;
        else if (key.includes("3")) return slide3;
        else return null;
    };

    return (
        <Carousel
            className={classes.carousel}
            indicators={false}
            interval={props.interval ? props.interval : 3000}
            timeout={1000}
            animation="fade"
            navButtonsAlwaysInvisible={true}
            swipe="true"
            navButtonsProps={{
                style: {
                    color: theme.palette.primary.main,
                    backgroundColor: theme.palette.secondary.main,
                    borderRadius: 25,
                },
            }}
            indicatorIconButtonProps={{
                style: {
                    padding: "10px",
                    color: "#ececec",
                },
            }}
            activeIndicatorIconButtonProps={{
                style: {
                    padding: "10px",
                    color: theme.palette.secondary.main,
                },
            }}
        >
            {Object.keys(props.items).map((key, index) => (
                <HashLink key={index} to={props.items[key].to ? props.items[key].to : ""}>
                    <div className={classes.item}>{getSlide(key)}</div>
                </HashLink>
            ))}
        </Carousel>
    );
};

export default withRouter(withWidth()(Slider));
