import React from "react";
import { Zoom, useScrollTrigger, Fab } from "@material-ui/core";
import { KeyboardArrowUp } from "@material-ui/icons";

const style = {
    position: `fixed`,
    bottom: `25px`,
    right: `25px`,
    zIndex: `99`,
};

const BackToTop = () => {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const scrollToTheTop = (event) => {
        const anchor = ((event && event.target.ownerDocument) || document).querySelector("#header-anchor");

        if (anchor) {
            anchor.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    };

    return (
        <>
            <Zoom in={trigger}>
                <div onClick={scrollToTheTop} role="presentation" style={style}>
                    <Fab color="secondary" size="medium" aria-label="scroll back to top">
                        <KeyboardArrowUp />
                    </Fab>
                </div>
            </Zoom>
        </>
    );
};

export default BackToTop;
