import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, Hidden, Box, withWidth } from "@material-ui/core";

const BannerPartners = (props) => {
    let logoMaxWidth = props.width === "xs" ? "100%" : "350px";
    return (
        <Box
            className={props.className}
            style={{
                width: "100%",
                backgroundImage: `url(${props.backgroundImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                ...props.styles.box,
            }}
        >
            <div style={props.styles.wrapper ? props.styles.wrapper : { minHeight: "200px" }}>
                <Container>
                    <Typography variant="h2" style={props.styles.title}>
                        {props.title ? props.title : "In partnership with"}
                    </Typography>
                    <Container>
                        {props.partners.map((partner, index) => {
                            return (
                                <a href={partner.link} target="blank">
                                    <img
                                        key={index}
                                        src={partner.logo}
                                        alt={partner.alt ? partner.alt : ""}
                                        style={{
                                            display: "block",
                                            margin: "25px auto 25px auto",
                                            maxWidth: logoMaxWidth,
                                        }}
                                    />
                                </a>
                            );
                        })}
                    </Container>
                </Container>
            </div>
        </Box>
    );
};

export default withWidth()(BannerPartners);
