import React, { useEffect } from "react";
import { Container, TextField, Button, Typography, Grid } from "@material-ui/core";
import Flag from "react-world-flags";

export default function ContactForm(props) {
    const errorMessage = "Oops, the request could not be processed! Please call us on our phone number.";
    const successMessage = "Thank you for contacting us!";

    const fields = ["name", "email", "phone", "message"];

    const [formValidity, setFormValidity] = React.useState({
        name: false,
        email: false,
        phone: false,
        message: false,
    });

    const [formState, setFormState] = React.useState({
        isSubmitted: false,
        isSuccess: false,
        isFailed: true,
        isValid: false,
        isEdited: false,
    });

    const [name, setName] = React.useState();
    const onChangeName = (event) => {
        let trimmedVal = event.target.value ? event.target.value : "";
        setName(trimmedVal);
        setValidityFor("name", trimmedVal !== "");
    };

    const [email, setEmail] = React.useState("");
    const onChangeEmail = (event) => {
        let trimmedVal = event.target.value ? event.target.value.trim() : "";
        setEmail(trimmedVal);
        setValidityFor("email", /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(trimmedVal));
    };

    const [phone, setPhone] = React.useState("");
    const onChangePhone = (event) => {
        let trimmedVal = event.target.value ? event.target.value.trim() : "";
        setPhone(trimmedVal);
        setValidityFor("phone", trimmedVal !== "");
    };

    const [message, setMessage] = React.useState("");
    const onChangeMessage = (event) => {
        let trimmedVal = event.target.value ? event.target.value : "";
        setMessage(trimmedVal);
        setValidityFor("message", trimmedVal !== "");
    };

    const setValidityFor = (field, isValid) => {
        let newFormValidity = { ...formValidity };
        newFormValidity[field] = isValid;
        setFormValidity(newFormValidity);

        let numOfValidFields = 0;
        fields.forEach((field) => {
            if (newFormValidity[field]) numOfValidFields = numOfValidFields + 1;
        });

        let newFormState = {
            ...formState,
            isSubmitted: false,
            isSuccess: false,
            isFailed: false,
            isValid: numOfValidFields === fields.length,
        };

        newFormState[field + "-edited"] = true;

        setFormState(newFormState);
    };

    const onSuccess = () => {
        setFormValidity({
            name: false,
            email: false,
            phone: false,
            message: false,
        });

        setName("");
        setEmail("");
        setPhone("");
        setMessage("");

        setFormState({
            isSubmitted: true,
            isSuccess: true,
            isFailed: false,
            isValid: false,
            isEdited: false,
        });
    };

    const onError = () => {
        setFormState({
            isSubmitted: true,
            isSuccess: false,
            isFailed: true,
            isValid: true,
        });
    };

    const handleSubmit = () => {
        if (formState.isValid) {
            let postBody = {
                subject: "Contact form sumbitted",
                attributes: {
                    name: name,
                    email: email,
                    phone: phone,
                    message: message,
                },
            };

            let onSuccessRef = onSuccess;
            let onErrorRef = onError;

            fetch(props.api, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(postBody),
            })
                .then((response) => {
                    if (response.status === 200) {
                        onSuccessRef();
                    } else {
                        onErrorRef();
                        console.log(JSON.stringify(response));
                    }
                })
                .catch((error) => {
                    onErrorRef();
                    console.log(error);
                });
        }
    };

    return (
        <>
            {props.showTitle && (
                <Container maxWidth={props.maxWidth}>
                    <Typography variant="h2">Contact</Typography>
                </Container>
            )}

            {props.flagCodes && (
                <Container maxWidth={props.maxWidth}>
                    <Typography variant="h3" align="center">
                        We speak in the following languages:
                    </Typography>
                    <Grid container xs={12} style={{ margin: "24px 0 0 0" }} justifyContent="space-around">
                        {props.flagCodes.map((flagCode) => {
                            return (
                                <Grid item xs={2} align="center">
                                    <Typography align="center">
                                        <Flag code={flagCode} height="24" />
                                    </Typography>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Container>
            )}

            {props.primaryPhone && (
                <Container>
                    <Typography variant="h2" align="center">
                        {props.primaryPhone}
                    </Typography>
                </Container>
            )}
            <Container>
                <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    onChange={onChangeName}
                    type="text"
                    value={name}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="normal"
                    error={!formValidity.name && formState["name-edited"]}
                />

                <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    onChange={onChangeEmail}
                    type="email"
                    value={email}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="normal"
                    error={!formValidity.email && formState["email-edited"]}
                />

                <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="phone"
                    label="Phone"
                    onChange={onChangePhone}
                    type="phone"
                    value={phone}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="normal"
                    error={!formValidity.phone && formState["phone-edited"]}
                />

                <TextField
                    variant="outlined"
                    required
                    multiline
                    rows="5"
                    fullWidth
                    id="message"
                    label="Message"
                    onChange={onChangeMessage}
                    type="text"
                    value={message}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="normal"
                    error={!formValidity.message && formState["message-edited"]}
                />

                {formState.isFailed && formState.isSubmitted && (
                    <Typography variant="body1" color="error">
                        {errorMessage}
                    </Typography>
                )}
                {formState.isSuccess && (
                    <Typography variant="body1" color="primary">
                        {successMessage}
                    </Typography>
                )}

                <Typography variant="body1" color="error" align="center">
                    <Button variant="contained" color="secondary" onClick={handleSubmit} disabled={!formState.isValid}>
                        Send
                    </Button>
                </Typography>
            </Container>
        </>
    );
}
