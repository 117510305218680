import React from "react";
import { withWidth, Container, Typography, Box } from "@material-ui/core";
import Slider from "./components/common/Slider";
// import Reviews from "./components/common/Reviews/Reviews";
// import BannerBoxes from "./components/common/BannerBoxes";
import BannerPartners from "./components/common/BannerPartners";
import TheKennelClubLogo from "./assets/The Kennel Club Logo.png";

// Slider configuration
function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => {
        images[item.replace("./", "").split(".")[0]] = r(item);
    });
    return images;
}

const sliderImages = importAll(require.context("./assets/slider", false, /\.(png|jpe?g|svg|PNG|JPE?G)$/));

const sliderItems = {
    image0: {
        line1: ``,
        line2: "Beautiful and stylish addition to your perfect home",
        line3: "",
        // to: "/services.html#live-online-personal-training-sessions",
    },
    image1: {
        line1: ``,
        line2: "A stylish addition to your home",
        line3: "",
        // to: "/services.html#live-online-personal-training-sessions",
    },
    image2: {
        line1: ``,
        line2: "The perfect companion in a home working environment",
        line3: "",
        // to: "/services.html#interactive-online-workshops",
    },
};

// Reviews configuration
const reviewItems = [
    {
        name: "Andy",
        rating: 5,
        text: `Great communication and very helpful with a tricky collection. Great service!`,
    },
    {
        name: "Jacob",
        rating: 5,
        text: `Did a great job for us, would highly recommend.`,
    },
    {
        name: "John",
        rating: 5,
        text: `This is a new company on the courier exchange and I have to say that I will use again , they had issues at the delivery point but kept us updated so we could try and resolve the problem. Thank you!`,
    },
    {
        name: "Paul",
        rating: 5,
        text: `Great job, definitely recommend, great communication. Thank you!`,
    },
    {
        name: "Sara",
        rating: 5,
        text: `Brilliant service, thank you!`,
    },
    {
        name: "Anna",
        rating: 5,
        text: `Brilliant company, will use again. Many thanks!`,
    },
    {
        name: "Steven",
        rating: 5,
        text: `Great communication and very helpful with a tricky collection. Great service!`,
    },
];

// Partners configuration

const partners = [
    {
        logo: TheKennelClubLogo,
        link: "https://www.thekennelclub.org.uk",
    },
];

function Home(props) {
    return (
        <>
            <Container maxWidth="xl" style={{ paddingBottom: "0" }}>
                <Slider images={sliderImages} items={sliderItems} interval={4000} />
            </Container>

            <BannerPartners
                className={props.classes.lightBox}
                styles={{
                    title: {
                        // backgroundColor: "rgba(0,0,0,0.75)",
                        // marginTop: "32px",
                    },
                    wrapper: { minHeight: "150px" },
                }}
                title="We are registered with"
                partners={partners}
            />

            {/* <Box className={props.classes.darkBox} id="reviews">
                <Reviews
                    showTitle={true}
                    reviews={reviewItems}
                    linkClassName={props.classes.standardLink}
                    api={"https://3fb54nlxbh.execute-api.eu-west-2.amazonaws.com/Prod/contact"}
                />
            </Box> */}
        </>
    );
}

export default withWidth()(Home);
