import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import {
    AppBar,
    Container,
    Toolbar,
    List,
    ListItem,
    ListItemText,
    Hidden,
    Menu,
    MenuItem,
    Fade,
    withWidth,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SideDrawer from "./SideDrawer";
import HideOnScroll from "./HideOnScroll";
import BackToTop from "./BackToTop";

const useStyles = makeStyles((theme) => ({
    containerDisplayFlex: {
        display: `flex`,
    },
    navDisplayFlex: {
        display: `flex`,
    },
    item: {
        // "&:hover": {
        //     backgroundColor: "inherit",
        // },
    },
    subItem: {
        padding: "0 8px 0 8px",
    },
    link: {
        paddingTop: "0px",
        textDecoration: `none`,
        textTransform: `uppercase`,
        backgroundColor: "transparent",
        color: "#355e3b",
    },
}));

const Header = (props) => {
    const classes = useStyles();
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    let paddingTop;

    switch (props.width) {
        case "xs":
            paddingTop = 175;
            break;
        case "sm":
            paddingTop = 125;
            break;
        default:
            paddingTop = 125;
            break;
    }

    useEffect(() => {
        const unlisten = props.history.listen(() => {
            scrollToTheTop();
        });
        return () => {
            unlisten();
        };
    }, []);

    const scrollToTheTop = () => {
        const anchor = document.querySelector("#header-anchor");
        anchor.scrollIntoView({ behavior: "auto", block: "center" });
    };

    const getLink = (navItem) => {
        if (navItem.path.includes("#")) {
            return (
                <HashLink to={navItem.path ? navItem.path : ""} className={classes.link}>
                    <ListItemText primary={navItem.title} disableTypography />
                </HashLink>
            );
        } else {
            return (
                <Link to={navItem.path ? navItem.path : ""} className={classes.link}>
                    <ListItemText primary={navItem.title} disableTypography />
                </Link>
            );
        }
    };

    const getNavItem = (navItem, index) => {
        if (navItem.path) {
            return (
                <ListItem key={"ListItem" + index} button disableRipple className={classes.item}>
                    {getLink(navItem)}
                </ListItem>
            );
        } else {
            return (
                <>
                    <ListItem
                        key={"ListItem" + index}
                        button
                        disableRipple
                        className={classes.item}
                        onClick={handleClick}
                        aria-controls="fade-menu"
                        aria-haspopup="true"
                    >
                        <ListItemText className={classes.link} primary={navItem.title} disableTypography />
                    </ListItem>

                    <Menu
                        key={"Menu" + index}
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                        id="fade-menu"
                        style={{ margin: "40px 0 0 0" }}
                        PaperProps={{
                            style: { backgroundColor: theme.palette.background.HeaderMenu },
                        }}
                    >
                        {navItem.children.map((childNavItem, index) => {
                            return (
                                <MenuItem
                                    key={"MenuItem" + index}
                                    onClick={handleClose}
                                    className={classes.item + " " + classes.subItem}
                                >
                                    {getLink(childNavItem)}
                                </MenuItem>
                            );
                        })}
                    </Menu>
                </>
            );
        }
    };

    return (
        <>
            <HideOnScroll {...props}>
                <AppBar position="fixed" style={props.styles.appBar}>
                    <Toolbar style={props.styles.toolBar}>
                        <Container
                            maxWidth="md"
                            style={props.styles.containerDisplayFlex}
                            className={classes.containerDisplayFlex}
                        >
                            <Link to="/">
                                <img src={props.siteLogo} alt="Logo" style={props.styles.logo} />
                            </Link>

                            <Hidden smDown>
                                <List
                                    component="nav"
                                    aria-labelledby="main navigation"
                                    style={props.styles.navDisplayFlex}
                                    className={classes.navDisplayFlex}
                                >
                                    {props.navLinks.map((navItem, index) => getNavItem(navItem, index))}
                                </List>
                            </Hidden>
                            <Hidden mdUp>
                                <SideDrawer
                                    {...props}
                                    drawerStyle={props.drawerStyle ? props.drawerStyle : { width: "200px" }}
                                    LinkClassName={classes.link}
                                    ItemClassName={classes.item}
                                />
                            </Hidden>
                        </Container>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Toolbar id="header-anchor" style={{ paddingTop: paddingTop }} />
            <BackToTop {...props} />
        </>
    );
};

export default withWidth()(withRouter(Header));
