import React from "react";
import { Link, withRouter } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { List, ListItem, ListItemText, Collapse } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

const SideDrawerItem = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const getLink = (navItem, style) => {
        if (navItem.path.includes("#")) {
            return (
                <HashLink
                    to={props.item.path ? props.item.path : ""}
                    style={{ width: "100%" }}
                    className={props.LinkClassName}
                >
                    <ListItemText style={style ? style : {}} disableTypography>
                        {props.item.title}
                    </ListItemText>
                </HashLink>
            );
        } else {
            return (
                <Link
                    to={props.item.path ? props.item.path : ""}
                    style={{ width: "100%" }}
                    className={props.LinkClassName}
                >
                    <ListItemText style={style ? style : {}} disableTypography>
                        {props.item.title}
                    </ListItemText>
                </Link>
            );
        }
    };

    const getListItem = () => {
        if (props.item.path) {
            return (
                <ListItem
                    key={"SideDrawerItem" + props.index}
                    button
                    disableRipple
                    onClick={props.toggleDrawer(false)}
                    className={props.ItemClassName}
                >
                    {getLink(props.item)}
                </ListItem>
            );
        } else {
            return (
                <>
                    <ListItem
                        key={"SideDrawerItem" + props.index}
                        button
                        disableRipple
                        onClick={handleClick}
                        className={props.ItemClassName}
                    >
                        <ListItemText className={props.LinkClassName} primary={props.item.title} disableTypography />
                        {open ? <ExpandLess color="secondary" /> : <ExpandMore color="secondary" />}
                    </ListItem>
                    <Collapse key={"SideDrawerItemCollapse" + props.index} in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {props.item.children.map((childNavItem, innerIndex) => {
                                return (
                                    <ListItem
                                        key={"SideDrawerItemCollapseListItem" + innerIndex}
                                        button
                                        disableRipple
                                        onClick={props.toggleDrawer(false)}
                                        className={props.ItemClassName}
                                    >
                                        {getLink(childNavItem, { paddingLeft: "10px" })}
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Collapse>
                </>
            );
        }
    };

    return getListItem();
};

export default withRouter(SideDrawerItem);
