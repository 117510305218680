import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { IconButton, Drawer, List } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import { useTheme } from "@material-ui/core/styles";
import SideDrawerItem from "./SideDrawerItem";

const SideDrawer = (props) => {
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) return;
        else setIsOpen(open);
    };

    console.log(theme);

    return (
        <>
            <IconButton
                color="primary"
                edge="start"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                disableRipple
                style={{ backgroundColor: "transparent" }}
            >
                <Menu fontSize="large"></Menu>
            </IconButton>
            <Drawer
                anchor="right"
                open={isOpen}
                onClose={toggleDrawer(false)}
                PaperProps={{ style: { backgroundColor: theme.palette.background.SideDrawer } }}
            >
                <div style={props.drawerStyle} role="presentation" onKeyDown={toggleDrawer(false)}>
                    <List component="nav">
                        {props.navLinks.map((item, index) => (
                            <SideDrawerItem
                                key={"SideDrawerItem" + index}
                                insex={index}
                                item={item}
                                ItemClassName={props.ItemClassName}
                                LinkClassName={props.LinkClassName}
                                toggleDrawer={toggleDrawer}
                            />
                        ))}
                    </List>
                </div>
            </Drawer>
        </>
    );
};

export default withRouter(SideDrawer);
