import React from "react";
import { withWidth, Box } from "@material-ui/core";
import ContactForm from "./components/form/ContactForm";

function Contact(props) {
    return (
        <Box className={props.classes.lightBox} style={{ marginTop: "80px" }}>
            <ContactForm
                showTitle={true}
                primaryPhone="+441234567890"
                // api="https://3fb54nlxbh.execute-api.eu-west-2.amazonaws.com/Prod/contact"
                //flagCodes={["gb", "hun", "ro", "hr", "rs"]}
            />
        </Box>
    );
}

export default withWidth()(Contact);
