import logo from "./logo.svg";
import "./App.css";
import { CssBaseline, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SiteLogo from "./assets/Logo.png";
import Header from "./components/navigation/Header";
import Footer from "./components/navigation/Footer";
import Home from "./Home";
import Contact from "./Contact";
import Puppies from "./Puppies";
import About from "./About";
import Gallery from "./Gallery";

const themeLight = createTheme({
    typography: {
        fontFamily: "'PT Sans', sans-serif",
        h1: {
            fontSize: "25px",
            textTransform: "uppercase",
            padding: " 8px 0  8px 0",
            color: "#355e3b",
            textAlign: "center",
        },
        h2: {
            fontSize: "22.5px",
            textTransform: "uppercase",
            padding: "8px 0  8px 0",
            color: "#355e3b",
            textAlign: "center",
        },
        h3: {
            fontSize: "18px",
            padding: " 8px 0  8px 0",
        },
        h4: {
            fontSize: "18px",
            padding: " 8px 0  8px 0",
        },
        h5: {
            fontSize: "18px",
            padding: " 8px 0  8px 0",
        },
        h6: {
            fontSize: "18px",
            padding: " 8px 0  8px 0",
        },
        body1: {
            fontSize: "15px",
            padding: " 8px 0  8px 0",
        },
    },
    palette: {
        background: {
            default: "#000000",
            SideDrawer: "#fff",
            HeaderMenu: "#fff",
            Footer: "#fff",
        },
        primary: {
            main: "#355e3b",
        },
        secondary: {
            main: "#355e3b",
        },
        default: {
            main: "#fff",
        },
    },
    overrides: {
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: "#000",
            },
        },
        MuiDialog: {
            paper: {
                padding: "0",
                margin: "0",
            },
        },
        MuiMenuItem: {
            colorPrimary: {
                backgroundColor: "white",
            },
        },
        MuiContainer: {
            root: {
                paddingTop: "24px",
                paddingBottom: "24px",
            },
        },
    },
    props: {
        MuiContainer: {
            maxWidth: "md",
        },
    },
    // shadows: ["none"],
});

const useStyles = makeStyles((theme) => ({
    standardLink: {
        textDecoration: `underline`,
        textTransform: `inherit`,
        color: "#355e3b",
    },
    reviewsLink: {
        textDecoration: `underline`,
        textTransform: `inherit`,
        color: "white",
    },
    darkBox: {
        backgroundColor: "rgba(0, 0, 0, 100) ",
        color: "white",
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(6),
        borderTop: "5px solid #355e3b",
    },
    lightBox: {
        backgroundColor: "rgba(255, 255, 255, 100) ",
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(6),
    },
    blogGridItem: {
        padding: theme.spacing(2),
    },
}));

const navLinks = [
    { title: `Home`, path: `/` },
    { title: `About`, path: `/about` },
    { title: `Puppies`, path: `/puppies` },
    { title: `Gallery`, path: `gallery` },
    { title: `Contact`, path: `/contact` },
];

const footerLinks = [
    { title: `Home`, path: `/` },
    { title: `About`, path: `/about` },
    { title: `Puppies`, path: `/puppies` },
    { title: `Gallery`, path: `/gallery` },
    { title: `Contact`, path: `/contact` },
];

const socialLinks = ["https://www.facebook.com/"];

// The app

function App() {
    let classes = useStyles();
    return (
        <MuiThemeProvider theme={themeLight}>
            <CssBaseline />

            <Router>
                <Header
                    navLinks={navLinks}
                    classes={classes}
                    siteLogo={SiteLogo}
                    styles={{
                        appBar: {
                            padding: "0 0 0 0",
                            background: "#000",
                            borderBottom: "solid 3px #355e3b",
                            //borderBottom: "5px solid #355e3b",
                            // background:
                            //     "linear-gradient(34deg, rgb(162, 6, 17), rgb(162, 6, 17) 15%, red 30%, red 50%, white 51%)",
                        },
                        toolBar: {
                            //background: `linear-gradient(90deg, rgb(255, 255, 255, 0), rgb(255, 255, 255, 0) 50%, rgb(255, 255, 255, 1) 51%)`,
                        },
                        containerDisplayFlex: {
                            //backgroundColor: "white",
                            // borderRadius: "60px 0 0 60px",
                            justifyContent: `space-around`,
                            padding: 0,
                            // background: `
                            //             linear-gradient(90deg, rgb(255, 255, 255, 1), rgb(255, 255, 255, 0.75) 175px, rgb(255, 255, 255, 0.75) 300px, rgb(255, 255, 255, 1) 100%),

                            //             white
                            //            `,
                        },
                        navDisplayFlex: {
                            justifyContent: `space-between`,
                            marginTop: "30px",
                            marginBottom: "25px",
                        },
                        logo: {
                            width: "175px",
                            float: "right",
                            margin: "0 0 0 25px",
                            padding: "15px 0 15px 0",
                        },
                    }}
                />
                <Switch>
                    <Route path="/" exact component={() => <Home classes={classes} />} />
                    <Route path="/about" exact component={() => <About classes={classes} />} />
                    <Route path="/puppies" exact component={() => <Puppies classes={classes} />} />
                    <Route path="/gallery" exact component={() => <Gallery classes={classes} />} />
                    <Route path="/contact" exact component={() => <Contact classes={classes} />} />
                </Switch>

                <Box className={classes.darkBox} id="reviews">
                    <Footer
                        navLinks={footerLinks}
                        socialLinks={socialLinks}
                        linkStyle={{
                            paddingTop: "0px",
                            textDecoration: `underline`,
                            textTransform: `uppercase`,
                            color: "#355e3b",
                        }}
                        copyright="Maroshegyi Vizsla Kennels"
                    />
                </Box>
            </Router>
        </MuiThemeProvider>
    );
}

export default App;
