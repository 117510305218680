import React from "react";
import { withWidth, Box } from "@material-ui/core";
import Gallery from "./components/common/Gallery";

function Contact(props) {
    return (
        <Box className={props.classes.lightBox} style={{ marginTop: "80px" }}>
            <Gallery />
        </Box>
    );
}

export default withWidth()(Contact);
