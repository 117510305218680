import React, { useState } from "react";
import { Container, Typography, GridList, GridListTile, Grid, Dialog, withWidth, Fab } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => {
        images[item.replace("./", "").split(".")[0]] = r(item);
    });
    return images;
}

const images = importAll(require.context("../../assets/gallery", false, /\.(png|jpe?g|svg|PNG|JPE?G)$/));

const useStyles = makeStyles((theme) => ({
    IMG_0001: {
        transform: "translateX(-63%)",
    },

    IMG_0523: {
        top: "auto",
        transform: "none",
        left: "auto",
    },

    IMG_4098: { top: "auto", transform: "none", left: "auto" },
}));

const Gallery = (props) => {
    const classes = useStyles(props.theme);

    const [imageKey, setImageKey] = useState("");
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    let isMobile = props.width === "xs" || props.width === "sm";

    let cellHeight;

    switch (props.width) {
        case "xs":
            cellHeight = 100;
            break;
        case "sm":
            cellHeight = 125;
            break;
        default:
            cellHeight = 175;
            break;
    }

    const onClickPicture = (imgKey) => {
        setImageKey(imgKey);
        setIsDialogOpen(true);
    };

    const onDialogClose = () => {
        setIsDialogOpen(false);
    };

    return (
        <Container>
            <Typography variant="h2" align="center">
                Gallery
            </Typography>

            {props.subtitle && (
                <Typography variant="h3" align="center">
                    {props.subtitle}
                </Typography>
            )}

            <Container>
                <GridList cols={4} cellHeight={cellHeight}>
                    {Object.keys(images).map((key, index) => {
                        return (
                            <GridListTile
                                key={index}
                                cols={1}
                                onClick={isMobile ? () => {} : () => onClickPicture(key)}
                            >
                                <img src={images[key].default} className={classes[key] ? classes[key] : ""} />
                            </GridListTile>
                        );
                    })}
                </GridList>

                <Dialog open={isDialogOpen} onClose={onDialogClose} maxWidth={false}>
                    <div onClick={onDialogClose} style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Fab color="secondary" size="small">
                            <Close />
                        </Fab>
                    </div>
                    {imageKey && <img src={images[imageKey].default} alt={imageKey} />}
                </Dialog>
            </Container>
        </Container>
    );
};

export default withWidth()(Gallery);
