import React from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Container, Grid, Typography, ListItem, Link, Box, IconButton } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

const Footer = (props) => {
    const getIcon = (link) => {
        if (link.includes("facebook")) return <FacebookIcon />;
        if (link.includes("twitter")) return <TwitterIcon />;
        if (link.includes("instagram")) return <InstagramIcon />;
        if (link.includes("wa.me")) return <WhatsAppIcon />;
    };

    const getLink = (path, title) => {
        if (path.includes("#")) {
            return (
                <HashLink to={path ? path : ""} style={props.linkStyle}>
                    {title}
                </HashLink>
            );
        } else {
            return (
                <RouterLink to={path ? path : ""} style={props.linkStyle}>
                    {title}
                </RouterLink>
            );
        }
    };

    return (
        <Container maxWidth="md">
            {props.navLinks && (
                <Box marginTop={3}>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        {props.navLinks.map(({ title, path }, index) => (
                            <Grid key={index} item align="center">
                                <ListItem>
                                    <Typography align="center">{getLink(path, title)}</Typography>
                                </ListItem>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
            {props.socialLinks && (
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    {props.socialLinks.map((link, index) => (
                        <Grid key={index} item align="center">
                            <ListItem>
                                <a href={link} target="_blank">
                                    <IconButton
                                        style={{ color: props.linkStyle.color }}
                                        aria-label="upload picture"
                                        component="span"
                                        disableRipple
                                    >
                                        {getIcon(link)}
                                    </IconButton>
                                </a>
                            </ListItem>
                        </Grid>
                    ))}
                </Grid>
            )}

            <Box marginBottom={3}>
                <Typography align="center">
                    Copyright {props.copyright && props.copyright}, Website and Hosting by{" "}
                    <Link href="http://agileigniter.com" style={props.linkStyle}>
                        AgileIgniter Limited
                    </Link>
                </Typography>
            </Box>
        </Container>
    );
};

export default withRouter(Footer);
