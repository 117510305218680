import React from "react";
import { withWidth, Box, Container, Typography, Grid, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import PuppyImage from "./assets/puppy.jpg";

let puppies = [
    {
        img: PuppyImage,
        title: "Maroshegyi Arthur",
        subTitle: "A very playful and social individual.",
        properties: ["1st", "285g", "male"],
        btnLabel: "Enquire about Arthur",
        to: "/contact",
    },
    {
        img: PuppyImage,
        title: "Maroshegyi Astor",
        subTitle: "Playful but a bit shy, a perfect family dog.",
        properties: ["2nd", "300g", "male"],
        btnLabel: "Enquire about Astor",
        to: "/contact",
    },
    {
        img: PuppyImage,
        title: "Maroshegyi Abigel",
        subTitle: "Energetic and playful, an excellent country side dog.",
        properties: ["3rd", "250g", "female"],
        btnLabel: "Enquire about Abigel",
        to: "/contact",
    },
];

function Puppies(props) {
    return (
        <Box className={props.classes.lightBox} style={{ marginTop: "80px" }}>
            <Container>
                <Typography variant="h2" align="center">
                    Puppies
                </Typography>

                {puppies.map((element, index) => {
                    return (
                        <Box key={index} className={props.classes.lightBox}>
                            <Container disableGutters>
                                <Grid container>
                                    <Grid item sm={12} md={6}>
                                        <img src={element.img} alt={element.title} style={{ width: "100%" }} />
                                    </Grid>
                                    <Grid item sm={12} md={6}>
                                        <Typography variant="h2" style={{ paddingTop: "25px" }}>
                                            {element.title}
                                        </Typography>
                                        <Box style={{ backgroundColor: "#fff", padding: "25px" }}>
                                            <Typography variant="h3" align="left" style={{ padding: "0 0 25px 0" }}>
                                                {element.subTitle}
                                            </Typography>
                                            <Typography variant="body1" align="left">
                                                <ul>
                                                    <li>Birth order: {element.properties[0]}</li>
                                                    <li>Birth weight: {element.properties[1]}</li>
                                                    <li>Gender: {element.properties[2]}</li>
                                                </ul>
                                            </Typography>
                                        </Box>
                                        {element.btnLabel && (
                                            <Typography align="center">
                                                <Button
                                                    component={Link}
                                                    to={element.to}
                                                    variant="contained"
                                                    color="primary"
                                                    disableRipple={true}
                                                    size="medium"
                                                >
                                                    {element.btnLabel}
                                                </Button>
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </Container>
                        </Box>
                    );
                })}
            </Container>
        </Box>
    );
}

export default withWidth()(Puppies);
